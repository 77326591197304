export function getPublickImgURL(img_path) {
  return `/images/front/${img_path}`
}

export function getMiniImgURL(img_path) {
  return `/images/front/mini/${img_path}`
}

export function getImageCset(img_path) {
  return `/images/front/mini/${img_path} 100w, /images/front/mobile/${img_path} 200w, /images/front/${img_path} 400w`
}
